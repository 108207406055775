import React from 'react';

import PreviewCompatibleImage from '../PreviewCompatibleImage';

// Sort images into two different arrays based on aspect ratio
export const filterImagesByAspectRatio = (images) => {
	const portraitOrientation = images.filter(
		({ image }) => image.childImageSharp.fluid.aspectRatio <= 1
	);

	const landscapeOrientation = images.filter(
		({ image }) => image.childImageSharp.fluid.aspectRatio > 1
	);

	return {
		portraitOrientation,
		landscapeOrientation,
	};
};

// Put image columns into an array, alternating two portrait, one landscape
export const gridArray = (portrait, landscape) => {
	let i = 0;
	let j = 0;
	let grid = [];

	while (i < portrait.length || j < landscape.length) {
		if (i < portrait.length) {
			if (portrait[i + 1]) {
				grid.push(portrait[i], portrait[i + 1]);
			} else {
				grid.push(portrait[i]);
			}
			i = i + 2;
		}
		if (j < landscape.length) {
			grid.push(landscape[j]);
			j++;
		}
	}

	return grid;
};

// Put items into React components
export const imageGrid = (items, clickHandler = undefined) => {
	// console.log(items);

	return items.map((item, i) => (
		<div
			className={`column ${
				item.image.childImageSharp.fluid.aspectRatio > 1 ? 'is-12' : 'is-6'
			}`}
			key={`${item.image.childImageSharp.fluid.src}`}
			onClick={clickHandler ? (e) => clickHandler(e) : undefined}
			data-index={i}
		>
			<PreviewCompatibleImage imageInfo={item} />
		</div>
	));
};

const ProjectGrid = ({ images, activateLightBox }) => {
	// Sort based on aspect ratio
	const {
		portraitOrientation,
		landscapeOrientation,
	} = filterImagesByAspectRatio(images);

	// Put image columns into an array, alternating two portrait, one landscape
	const imageItems = gridArray(portraitOrientation, landscapeOrientation);

	// Put items into React components
	return imageGrid(imageItems, activateLightBox);
};

export default ProjectGrid;
