import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
	filterImagesByAspectRatio,
	gridArray,
} from '../image-grid/ProjectGrid';

import logo from '../../img/pnr-footer-logo.svg';
import close from '../../img/close-dark.svg';

const LightBox = styled.div`
	.slick-list,
	.slick-slider,
	.slick-track {
		position: relative;
		display: block;
	}

	.slick-slider,
	.lightbox-header,
	.back-button {
		height: 0;
		overflow: hidden;
		opacity: 0;
		transition: opacity 300ms;
	}

	&.active {
		.slick-slider {
			position: fixed;
			background-color: #f5f5f1;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1000;
			height: auto;
			width: 100%;
			opacity: 1;

			/* Hide native navigation buttons */
			.slick-arrow {
				display: none !important;
			}

			.slick-list {
				.slick-track {
					top: 0;
					left: 0;

					.slick-slide {
						position: relative;

						@media (max-width: 768px) {
							padding-top: 120px;
							padding-right: 7px;
							padding-left: 7px;
							max-width: 100vw;
							max-height: 55vh;
						}

						@media (min-width: 769px) {
							padding-top: 50px;
							padding-top: 19vh;
							padding-right: 15px;
							padding-left: 15px;
							max-height: 500px;
							max-height: 100vh;
							max-width: 500px;
							max-width: 80vw;
						}

						> div {
							> div {
								position: relative;
							}
						}

						img {
							transition: opacity 300ms ease-in-out;
							opacity: 0.3;
							max-height: 100%;
							transition: opacity 0.5s ease-in-out;
							transition-delay: 0.3s;

							&:focus {
								outline: none;
							}

							@media (max-width: 768px) {
								height: auto;
								margin: 0 auto;
							}

							@media (min-width: 769px) {
								height: 70vh;
								width: auto !important;
							}
						}

						&.slick-current {
							opacity: 1;

							.carousel-button {
								opacity: 1;
								z-index: 5;
							}

							img {
								opacity: 1;
							}
						}

						.carousel-button {
							position: absolute;
							top: calc(50% - 25px);
							background: #f5f5f1;
							border: none;
							width: 50px;
							height: 50px;
							cursor: pointer;
							transition: all 0.5s ease-in-out;
							transition-delay: 0.3s;
							opacity: 0;
							z-index: 0;

							&.next-button {
								right: -80px;
							}

							&.prev-button {
								left: -80px;
							}

							&:focus {
								outline: none;
							}

							@media (max-width: 768px) {
								display: none;
							}
						}
					}
				}
			}
		}

		.lightbox-header {
			height: 19vh;
			opacity: 1;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1000;

			h2 {
				position: fixed;
				width: 100%;
				top: 80px;

				@media (min-width: 769px) {
					top: 15vh;
				}
			}

			.lightbox-close-button {
				position: absolute;
				top: 2rem;
				right: 5vw;
				background: none;
				border: none;
				width: 60px;
				height: 60px;
			}

			.logo-link {
				position: absolute;
				top: 3rem;
				left: 5vw;
				width: 250px;

				@media (max-width: 768px) {
					display: none;
				}
			}
		}

		.back-button {
			height: auto;
			opacity: 1;
			position: fixed;
			bottom: 2.75vh;
			left: 50%;
			transform: translateX(-50%);
			z-index: 1000;
		}
	}
`;

const ProjectLightBox = ({ title, images, imageIndex, active, setActive }) => {
	// Sort based on aspect ratio
	const {
		portraitOrientation,
		landscapeOrientation,
	} = filterImagesByAspectRatio(images);

	// Order the images according to grid layout
	const imageItems = gridArray(portraitOrientation, landscapeOrientation);

	// Prev and Next buttons for carousel

	const settings = {
		infinite: true,
		centerMode: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		easing: 'ease-in-out',
	};

	const slider = React.createRef();

	useEffect(() => {
		slider.current.slickGoTo(imageIndex);
	});

	return (
		<LightBox className={active ? 'active' : undefined}>
			<Slider {...settings} ref={slider}>
				{imageItems.map((item, i) => (
					<div key={`${item.image.childImageSharp.fluid.src}-${i}`}>
						<img src={item.image.childImageSharp.fluid.src} alt={item.alt} />
						<button
							className="carousel-button next-button"
							onClick={() => slider.current.slickNext()}
						>
							<FaChevronRight />
						</button>
						<button
							className="carousel-button prev-button"
							onClick={() => slider.current.slickPrev()}
						>
							<FaChevronLeft />
						</button>
					</div>
				))}
			</Slider>
			<div className="lightbox-header">
				<Link className="logo-link" to="/">
					<img src={logo} alt="Peaks and Rafters Home Page" />
				</Link>
				<h2 className="has-text-centered">{title}</h2>
				<button
					className="lightbox-close-button"
					onClick={() => setActive(!active)}
				>
					<img src={close} alt="" />
					<span className="visually-hidden">Close</span>
				</button>
			</div>
			<button
				className="back-button text-link dark"
				onClick={() => setActive(!active)}
			>
				Back to Project &gt;
			</button>
		</LightBox>
	);
};

export default ProjectLightBox;
