import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import slugify from 'slugify';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ProjectGrid from '../components/image-grid/ProjectGrid';
import ProjectLightBox from '../components/carousels/ProjectLightBox';
import BackToTopButton from '../components/BackToTopButton';

import RegularPage from '../styles/regularPage';

const StyledArticle = styled(RegularPage)`
	.intro-section {
		padding-top: 0;
		padding-bottom: 4rem;
	}

	.images-section {
		.backtotop-container {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 5rem;
			text-align: right;
			padding-right: 3rem;
			z-index: 99;
			pointer-events: none;
		}
	}
	.images-row {
		padding: 0.5rem;
		margin-bottom: 5rem;

		> .column {
			padding: 0.5rem;
			cursor: pointer;
		}
	}
`;

export const ProjectTemplate = ({ title, images, slug }) => {
	const [slideIndex, setSlideIndex] = useState(undefined);
	const [isActive, setIsActive] = useState(false);

	const activateLightBox = (e) => {
		// Get the index of the current image clicked
		const theIndex = parseInt(e.currentTarget.dataset.index, 10);

		// Set state with index of image clicked
		setSlideIndex(theIndex);

		// Set active flag
		setIsActive(!isActive);
	};

	const id = slugify(slug);

	return (
		<StyledArticle>
			<section className="section intro-section">
				<div className="container">
					<div className="columns">
						<div className="column">
							<h1>{title}</h1>
						</div>
					</div>
				</div>
			</section>
			<section className="section images-section">
				<div className="container">
					<div className="columns is-multiline is-mobile images-row">
						<ProjectGrid
							images={images}
							activateLightBox={(e) => activateLightBox(e)}
						/>
					</div>
					<div className="columns">
						<div className="column has-text-centered button-row">
							<Link className="text-link dark" to={`/our-work/#${id}`}>
								Back to Our Work &gt;
							</Link>
						</div>
					</div>
					<ProjectLightBox
						title={title}
						images={images}
						imageIndex={slideIndex}
						active={isActive}
						setActive={() => setIsActive()}
					/>
					<div className="backtotop-container container">
						<BackToTopButton />
					</div>
				</div>
			</section>
		</StyledArticle>
	);
};

ProjectTemplate.propTypes = {
	title: PropTypes.string,
};

const Project = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { slug } = data.markdownRemark.fields;

	return (
		<Layout background="light">
			<SEO title={frontmatter.title} />
			<ProjectTemplate
				title={frontmatter.title}
				images={frontmatter.images}
				slug={slug}
			/>
		</Layout>
	);
};

Project.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default Project;

export const projectQuery = graphql`
	query Project($id: String!) {
		markdownRemark(id: { eq: $id }) {
			fields {
				slug
			}
			frontmatter {
				title
				images {
					alt
					image {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`;
