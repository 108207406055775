import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
	background: none;
	border: none;
	font-size: 1.8rem;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	color: ${(props) => props.theme.colors.lightest};
	padding: 0.5em 1em;
	opacity: 0;
	transition: 300ms;
	cursor: pointer;
	pointer-events: all;

	&.scrolled {
		opacity: 1;
	}

	&:hover {
		background-color: ${(props) => props.theme.colors.darkest};
	}
`;

const scrollToTop = () => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;

	if (c > 0) {
		window.requestAnimationFrame(scrollToTop);
		window.scrollTo(0, c - c / 8);
	}
};

const BackToTopButton = () => {
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		window.onscroll = () => {
			if (
				document.body.scrollTop > 20 ||
				document.documentElement.scrollTop > 20
			) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};
	}, []);

	return (
		<StyledButton
			className={scrolled && `scrolled`}
			onClick={() => scrollToTop()}
		>
			TOP
		</StyledButton>
	);
};

export default BackToTopButton;
